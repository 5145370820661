
import {
  defineComponent,
  computed,
  watch,
  ref,
  Ref,
  unref,
  onMounted,
  onUnmounted,
  toRefs,
  reactive,
  onUpdated,
  nextTick,
  toRaw,
  onBeforeUpdate,
} from "vue";
import { demoData } from "./config/dashBoard";
import { getDashBoard, reboundDevice, getDashNew, getDashCount, getLocationApi } from "@/api/devices";
// import { getLocationApi } from "@/api/map";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { dateFormat, dateFormatAm } from "@/utils/format";
import numberItem from "./tabItem/numberItem.vue";
import appItem from "./tabItem/appItem.vue";
import { ElMessage } from "element-plus";
import emitter from "@/utils/eventBus";
import { fa } from "element-plus/es/locale";
import { getQueryParam } from "@/utils/format";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";
import { unbindAndEmptyDataApi, photoInfo, syncIOSPhotosApi, iosPhotosApi } from "@/api/devices";
import { unbindIcloud, icloudRebound } from "@/api/ios/index";
import noticeDialog from "./tabItem/noticeDialog.vue";
import messageToast from "@/components/common/messageToast.vue";
import Tooltip from "@/components/common/tooltip.vue";
import { useCheckNotification } from "@/components/common/hooks";
import { refreshTab } from "@/utils/refresh";
import * as echarts from 'echarts';
import { convertSecondsToHoursMinutes, convertSecondsToHoursMinutesSecond } from "@/views/screen/component/fomat";
import mapBox from '@/components/mapbox/map.vue'
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { getAddressInfo } from "@/utils/mapFun";

// @ts-ignore
import iphone from '@/assets/ios/big_phone.svg';
// @ts-ignore
import Launcher from './img/launcher.svg';
// @ts-ignore

export default defineComponent({
  name: "homePage",
  components: {
    numberItem,
    appItem,
    mapBox,
    noticeDialog,
    messageToast,
    Tooltip
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    let router = useRouter();
    // loading 控制骨架屏的显示
    const loading = ref(true);

    const messageToastType = computed(() => store.state.messageToastType);
    const beExpired = computed(() => store.state.beExpired);
    const isExpired = computed(() => store.state.isExpired);
    const isVibe = computed(() => !store.state.isVibe);

    watch([beExpired], () => {
      gaEvent("Notice_7day", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
    });
    watch([isExpired], () => {
      gaEvent("Notice_Expired", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
    });

    // 过期时间
    const subscripts = computed(() => {
      return store.state.subscripts?.expires_at;
    });
    const lang = computed(() => store.state.currentLang);

    // 方便埋点
    const targetDeviceInfo = computed(() => {
      return store.state.targetDeviceLength;
    });
    const deviceInfo = computed(() => {
      return store.state.deviceInfo;
    });

    const id = computed(() => {
      return store.state.currentDeviceId;
    });

    // 自动续费
    const autoRenew = computed(() => {
      return store.state.autoRenew;
    });

    // 让设备信息和账号信息高度一致
    const deveiceHeight = ref(232);

    const currentDeviceId = computed(() => store.state.currentDeviceInfo.id);
    const currentDevice = computed(() => store.state.currentDeviceInfo.name);
    const currentDeviceModel = computed(
      () => store.state.currentDeviceInfo.model
    );
    const isActive = computed(() => store.state.currentDeviceInfo.is_active);
    const storage_usage = computed(() => store.state.currentDeviceInfo.storage_usage);
    const isDemo = computed(() => store.state.isDemo);
    const deviceId = computed(() => store.state.currentDeviceInfo.id);
    const defaultMenu = computed(() => store.state.defaultMenu);

    let account = ref<any[]>([]);
    // 因为不能直接给一个ref为对象的变量直接赋值，所以要给一个泛型，来表明它接收的对象
    // android设备展示的内容
    let targetDevice = ref<any[]>([]);
    // ios设备展示的内容
    let iosDevice = ref<any[]>([]);
    // whatsapp模块
    let active_calls = ref({});
    let active_session = ref({});
    // instagram模块
    let ins_calls = ref({});
    let ins_session = ref({});

    const syncLoading = computed(() => (store.state as any).headerStore.syncLoading)

    // 点击同步按钮触发
    const handleUpdateClick = async () => {
      gaEvent("Sync", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      if (isVibe.value) {
        ElMessage.error(t("famikeep200['The demo mode does not support this feature, please bind the device first.']"));
        return
      }
      store.commit("setIsClickUpdateBtn", true);
      const updateIconDom = document.querySelector(".updateIcon");
      updateIconDom?.classList.add("run");
      // 刷新页面
      if (deviceType.value) {
        await store.dispatch("getDeviceInfo")
        await store.dispatch("getCurrentIcloudInfo")
        await getGeofencFun()
        await getPhotoList()

        if (showRebound.value) {
          store.commit('setMessageToastType', 3);
        } else {
          let params = {
            apple_id: currentAppleId.value
          }
          try {
            let res = await syncIOSPhotosApi(params);
            console.log('resss:', res);
            if (res.data.code == 200) {
              ElMessage({
                message: t("chat.syncSuccessed"),
                type: "success",
              });
            }
          } catch (err: any) {
            ElMessage({
              message: t("chat.syncFailed"),
              type: "error",
            });
            if (err.response) {
              console.log('err.response', err.response);
              if (err.response.data.code == 401003) {
                console.log('401003');
                // 如果已过期就不显示重新登录的通知
                if (messageToastType.value !== 3) {
                  store.commit('setMessageToastType', 4);
                  store.commit('setIsExpiredLogin', true)
                }
              }
            }
          }
        }

      } else {
        window.location.reload();
      }
      setTimeout(() => {
        updateIconDom?.classList.remove("run");
      }, 1000);
    };

    if (!isDemo.value) {
      active_calls = ref({});
      active_session = ref({});
      ins_calls = ref({});
      ins_session = ref({});
      account.value = [
        {
          src: require("@/components/homePage/img/message.svg"),
          text: computed(() => t("dashboard.id")),
          content: computed(() => store.state.userInfo.email),
          singular: true,
        },
        {
          src: require("@/components/homePage/img/time.svg"),
          text: computed(() => t("dashboard.expire")),
          content: computed(() => {
            return store.state.currentDeviceInfo.expire_at;
          }),
          singular: false,
          data: true,
        },
        {
          src: require("@/components/homePage/img/accPhone.svg"),
          text: computed(() => t("dashboard.bound")),
          content: computed(() => store.state.boundedDevice),
          singular: true,
        },
        {
          src: require("@/components/homePage/img/accPhone.svg"),
          text: computed(() => t("dashboard.restDevice")),
          content: computed(() => {
            if (store.state.subscripts.total_effective_pit > 0) {
              return store.state.subscripts.total_effective_pit;
            } else {
              return "0";
            }
          }),
          singular: false,
        },
      ];

      targetDevice.value = [
        {
          src: require("@/components/homePage/img/phone.svg"),
          text: computed(() => t("dashboard.model")),
          content: computed(() => `${store.state.currentDeviceInfo.model}-${store.state.currentDeviceInfo.brand}`),
          singular: false,
        },
        {
          src: require("@/components/homePage/img/android.svg"),
          text: computed(() => t("dashboard.version")),
          content: computed(
            () => store.state.currentDeviceInfo.android_version
          ),
          singular: true,
        },
        {
          src: require("@/components/homePage/img/update.svg"),
          text: computed(() => t("dashboard.update")),
          content: computed(() => store.state.currentDeviceInfo.last_update_at),
          singular: false,
          data: true,
        },
        {
          src: require("@/components/homePage/img/battery-working-one.svg"),
          text: computed(() => t("dashboard.battery")),
          content: computed(() => store.state.currentDeviceInfo.battery),
          battery: true,
          singular: true,
        },

        {
          src: require("@/components/homePage/img/wifi.svg"),
          text: computed(() => t("dashboard.wifi")),
          content: computed(() => store.state.currentDeviceInfo.wifi),
          singular: false,
        },

        {
          src: require("@/components/homePage/img/info.svg"),
          text: computed(() => t("dashboard.appversion")),
          content: computed(() =>
            !store.state.currentDeviceInfo.apk_version
              ? store.state.currentDeviceInfo.apk_version
              : store.state.currentDeviceInfo.apk_version.substring(0, 5)
          ),
          singular: true,
        },
      ];

      iosDevice.value = [
        {
          src: require("@/components/homePage/img/icloudID.svg"),
          text: computed(() => t("Apple ID")),
          content: computed(() => store.state.currentAppleId),
          singular: false,
        },
        {
          src: require("@/components/homePage/img/icloudSpace.svg"),
          text: computed(() => t(("famikeep210['iCloud used storage']"))),
          content: computed(
            () => store.state.currentDeviceInfo.storage_usage
          ),
          singular: true,
        },
        {
          src: require("@/components/homePage/img/update.svg"),
          text: computed(() => t("dashboard.update")),
          content: computed(() => store.state.currentDeviceInfo.last_update_at),
          singular: false,
          data: true,
        },
      ];
    }

    const getCurrentDashBoard = async () => {
      const params = {
        device_id: id.value,
      };
      try {
        if (isDemo.value) {
          loading.value = false;
          return;
        }
        const res = await getDashBoard(params);

        if (res.data.code === 200 && res.data.message === "OK") {
          // 请求成功之后就关闭骨架屏
          loading.value = false;
          if (
            res.data.data.whatsapp_active_calls ||
            res.data.data.whatsapp_active_session
          ) {
            if (res.data.data.whatsapp_active_calls) {
              active_calls.value = res.data.data.whatsapp_active_calls;
            }

            // 因为两组数据的title名字不一样，所以用map处理，为了复用组件
            if (res.data.data.whatsapp_active_session) {
              active_session.value = res.data.data.whatsapp_active_session.map(
                (item: any) => {
                  return {
                    ...item,
                    contact_name: item.chat_session_title,
                  };
                }
              );
            } else {
              active_session.value = {};
            }
          } else {
            active_calls.value = {};
            active_session.value = {};
          }

          if (
            res.data.data.instagram_active_calls ||
            res.data.data.instagram_active_session
          ) {
            if (res.data.data.instagram_active_calls) {
              ins_calls.value = res.data.data.instagram_active_calls;
            }

            // 因为两组数据的title名字不一样，所以用map处理，为了复用组件
            if (res.data.data.instagram_active_session) {
              ins_session.value = res.data.data.instagram_active_session.map(
                (item: any) => {
                  return {
                    ...item,
                    contact_name: item.chat_session_title,
                  };
                }
              );
            } else {
              ins_session.value = {};
            }
          } else {
            ins_calls.value = {};
            ins_session.value = {};
          }
        }
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    };

    const deviceType = computed(() => store.state.deviceType)

    onMounted(async () => {
      console.log('dateFormatAm(1693379303)', dateFormatAm(1693379303));

      let data = new Date().getTime();
      loading.value = true;
      if (id.value) {
        console.log("id:id:", deviceType.value)
        await store.dispatch("getDeviceInfo")
        if (deviceType.value) {
          store.dispatch("getCurrentIcloudInfo")
          await getGeofencFun()
          await getPhotoList()

          const location = locationsList.value.length > 0 ? "Data" : "NoData";
          const photo = photoListArray.value.length > 0 ? "Data" : "NoData";
          const str = location + "_" + photo

          gaEvent("iOSDashboard_Data", {
            event_category: "Dashboard_Function",
            event_label: str,
          });

          console.log('showRebound.value', !showRebound.value);

          if (!showRebound.value) {
            let params = {
              apple_id: currentAppleId.value
            }
            try {
              let res = await syncIOSPhotosApi(params);
              console.log('resss:', res);
              if (res.data.code == 200) {
              }
            } catch (err: any) {
              ElMessage({
                message: t("chat.syncFailed"),
                type: "error",
              });
              if (err.response) {
                console.log('err.response', err.response);
                if (err.response.data.code == 401003) {
                  console.log('401003');
                  // 如果已过期就不显示重新登录的通知
                  if (messageToastType.value !== 3) {
                    store.commit('setMessageToastType', 4);
                    store.commit('setIsExpiredLogin', true)
                  }
                }
              }
            }
          } else {
            store.commit('setMessageToastType', 3);
          }

        } else {
          console.log('onMounted getCurrentDeviceInfo');
          store.dispatch("getCurrentDeviceInfo");
          await getCurrentDashBoard();
          await getCurrDashNew()
          await getScreenCount()
          await getGeofencFun()

          const location = locationsList.value.length > 0 ? "Data" : "NoData";
          const average = daliyTime.value ? "Data" : "NoData";
          const ins_call = (ins_session.value as any)?.length > 0 ? "Data" : "NoData";
          const whatsapp_call = (active_session.value as any)?.length > 0 ? "Data" : "NoData";
          const str = location + "_" + average + "_" + whatsapp_call + "_" + ins_call;

          gaEvent("Dashboard_Data", {
            event_category: "Dashboard_Function",
            event_label: str,
          });
        }
        // console.log("id:id:", currentDeviceId.value);
      }
      // console.log("first");
      if (window.location.href.includes("home")) {
        store.commit("setDefaultMenu", "home");
      }
      gaEvent("Dashboard_PV", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
    });

    onMounted(() => {
      // 此api放在onMounted中，可以监听dom的变化
      const resizeObserver = new ResizeObserver((entries) => {
        // 获取较高者账号信息的高度
        deveiceHeight.value = entries[0].borderBoxSize[0].blockSize;
      });
      resizeObserver.observe(document.getElementById("account") as any);
    });

    onUnmounted(() => {
      clearInterval(closeFiveM);
    })

    watch(deviceType, (newV) => {
      // 销毁定时器
      console.log('newV:', newV);

      if (newV) {
        console.log('xiaohuil');
        clearInterval(closeFiveM);
      }
    })


    // 这里如果换成当前设备信息里面的id，会导致第一次登录进来时不请求
    watch([id], async (newValue, oldVaule) => {
      if (!window.location.href.includes("guide")) {
        loading.value = true;
        setTimeout(() => {
          loading.value = false;
        }, 1000);
      }
      console.log("watch id", deviceType.value);
      if (deviceType.value) {
        if (defaultMenu.value !== 'iosLogin') {
          await store.dispatch("getDeviceInfo")
          await store.dispatch("getCurrentIcloudInfo")
          await getGeofencFun()
          await getPhotoList()

          console.log('showRebound.value', !showRebound.value);
          if (showRebound.value) {
            store.commit('setMessageToastType', 3);
          } else {
            let params = {
              apple_id: currentAppleId.value
            }
            try {
              let res = await syncIOSPhotosApi(params);
              console.log('resss:', res);
              if (res.data.code == 200) {
                // ElMessage({
                //   message: t("chat.syncSuccessed"),
                //   type: "success",
                // });
              }
            } catch (err: any) {
              // ElMessage({
              //   message: t("chat.syncFailed"),
              //   type: "error",
              // });
              if (err.response) {
                console.log('err.response', err.response);
                if (err.response.data.code == 401003) {
                  console.log('401003');
                  // 如果已过期就不显示重新登录的通知
                  if (messageToastType.value !== 3) {
                    store.commit('setMessageToastType', 4);
                    store.commit('setIsExpiredLogin', true)
                  }
                }
              }
            }
          }
        }
      } else {
        console.log('watch getCurrentDeviceInfo');

        store.dispatch("getCurrentDeviceInfo");
        appUseList.value = []
        locationsList.value = []
        await getCurrentDashBoard();
        await getCurrDashNew()
        await getScreenCount()
        await getGeofencFun()

        gaEvent("Dashboard_PV", {
          event_category: "Dashboard_Function",
          event_label: "None",
        });
        const location = locationsList.value.length > 0 ? "Data" : "NoData";
        const average = daliyTime.value ? "Data" : "NoData";
        const ins_call = (ins_session.value as any)?.length > 0 ? "Data" : "NoData";
        const whatsapp_call = (active_session.value as any)?.length > 0 ? "Data" : "NoData";
        const str = location + "_" + average + "_" + whatsapp_call + "_" + ins_call;
        gaEvent("Dashboard_Data", {
          event_category: "Dashboard_Function",
          event_label: str,
        });
      }

      if (!isVibe.value) {
        clearInterval(closeFiveM);
        if (!deviceType.value) {
          setNewInterval()
        }
      }
    });

    let closeFiveM: any
    // 设置新的定时器
    const setNewInterval = () => {
      if (!deviceType.value) {
        closeFiveM = setInterval(() => {
          console.log('setNewInterval getCurrentDeviceInfo');
          store.dispatch("getCurrentDeviceInfo");
          getCurrentDashBoard();
          getCurrDashNew()
          getScreenCount()
          getGeofencFun()

          console.log('重新设置定时器');
          // setTimeout(()=>{
          //   renderBarChart()
          // },1000)
        }, 1000 * 60 * 5);
        // }, 1000 * 10);
      }
    }
    if (!deviceType.value) {
      console.log('安卓定时更新');
      setNewInterval()
    } else {
      clearInterval(closeFiveM);
    }
    // 监听当前设备信息接口中的设备是否过期，过期就取消五分钟定时更新
    watch(currentDeviceId, () => {
      if (store.state.currentDeviceInfo.is_expired || deviceType.value) {
        clearInterval(closeFiveM);
      }
    });

    const jumpToCall = () => {
      store.commit("setWhatsappFrom", "SeeAll_Call");
      store.commit("setDefaultMenu", "whatsapp");
      router.push({ path: "whatsapp" });
      store.commit("setActiveTabName", "third");
    };

    const jumpToContact = () => {
      store.commit("setWhatsappFrom", "SeeAll_Contect");
      store.commit("setDefaultMenu", "whatsapp");
      router.push({ path: "whatsapp" });
      store.commit("setActiveTabName", "first");
    };

    const jumpToInsContact = () => {
      store.commit("setAppFrom", "SeeAll_Contect");
      store.commit("setDefaultMenu", "instagram");
      router.push({ path: "instagram" });
      store.commit("setActiveTabName", "first");
    };

    const jumpToInsCall = () => {
      store.commit("setAppFrom", "SeeAll_Call");
      store.commit("setDefaultMenu", "instagram");
      router.push({ path: "instagram" });
      store.commit("setActiveTabName", "third");
    };

    // 跳转到地图
    const jumpToLocation = () => {
      store.commit("setDefaultMenu", 'locations');
      router.push({ path: "locations" });
    }

    // 跳转到照片
    const jumpToPhoto = () => {
      store.commit("setDefaultMenu", 'photos');
      router.push({ path: "photos" });
    }

    const goPay = (text: String) => {
      // console.log('text:', text)
      if (text == "btn") {
        gaEvent("Renew", {
          event_category: "Dashboard_Function",
          event_label: "From_Button",
        });
      } else if (text == "pop") {
        gaEvent("Renew", {
          event_category: "Dashboard_Function",
          event_label: "From_Popups",
        });
      } else {
        gaEvent("Renew", {
          event_category: "Dashboard_Function",
          event_label: "From_Dashboard",
        });
        if (text == "btn7") {
          gaEvent("Notice_7day_Renew", {
            event_category: "Dashboard_Function",
            event_label: "None",
          });
        } else if (text == "btned") {
          gaEvent("Notice_Expired_Renew", {
            event_category: "Dashboard_Function",
            event_label: "None",
          });
        }
      }

      closeNoLocation();
      // closeExpired()
      store.commit("setDefaultMenu", null);
      store.commit("setPayFrom", 2);
      router.push({ path: "addDevice" });
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Renew",
      });
    };

    // 是否展示解绑浮窗
    const showUnbind = ref(false);
    // 控制解绑dialog的显隐
    const showUnbindDialog = ref(false);
    // 控制换绑的dialog
    const showReboundDialog = ref(false);
    // 是否展示换绑按钮
    const showRebound = computed(() => {
      return store.state.currentDeviceInfo.is_expired;
    });
    // 换绑失败弹窗的出现
    const showReboundFailed = ref(false);
    // 换绑中
    const showRebounding = ref(false);
    // 没多余位置换绑
    const showNoLocation = ref(false);
    // 权限过期
    // const showExpired = ref(false)

    const isOut = ref(true);

    // 点击三个点控制解除绑定的浮窗显隐
    const toUnbind = () => {
      showUnbind.value = !showUnbind.value;
    };

    const currentAppleId = computed(() => store.state.currentAppleId)

    // 点击浮窗之外的地方让浮窗消失
    const handleClickOutside = () => {
      showUnbind.value = false;
    };

    // 点击浮窗之外让iosList消失
    const handleClickIosList = () => {
      showIosList.value = false;
    };

    // 点击解绑浮窗，浮窗消失，dialog出现
    const unbindDevice = () => {
      gaEvent("Unbind", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      showUnbind.value = false;
      showUnbindDialog.value = true;
    };

    // 控制解绑dialog消失
    const closeDialog = () => {
      gaEvent("Unbind_PopClose", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      showUnbindDialog.value = false;
    };
    // 控制换绑dialog消失
    const closeReboundDialog = () => {
      gaEvent("ChangeBind_PopClose", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      showReboundDialog.value = false;
    };
    // 控制没权限dialog的消失
    const closeNoLocation = () => {
      showNoLocation.value = false;
    };

    const closeReboundFailed = () => {
      showReboundFailed.value = false;
    };

    // 点击dialog确定解绑
    const confirmUnbind = async () => {
      showUnbindDialog.value = false;
      try {
        console.log('deviceType.value:', deviceType.value);

        if (!deviceType.value) {
          const res = await unbindAndEmptyDataApi(currentDeviceId.value);
          if (res.data.code == 200) {
            await refreshTab();
            // location.reload();
            ElMessage.success(t("dashboard.unbindSuc"));
            gaEvent("Unbind_Success", {
              event_category: "Dashboard_Function",
              event_label: "None",
            });
          } else {
            // console.log('else')
          }
        } else {
          const res = await unbindIcloud(currentAppleId.value);
          if (res.data.code == 200) {
            await refreshTab();
            ElMessage.success(t("dashboard.unbindSuc"));
            gaEvent("Unbind_Success", {
              event_category: "Dashboard_Function",
              event_label: "None",
            });
          } else {
            // console.log('else')
          }
        }
      } catch (err) {
        console.log("err");
        ElMessage({
          message: t("dashboard.unbindFail"),
          type: "error",
        });
        gaEvent("Unbind_Fail", {
          event_category: "Dashboard_Function",
          event_label: "None",
        });
      }
    };

    // 点击确定换绑
    const confirmRebind = async () => {
      showReboundDialog.value = false;
      showReboundFailed.value = false;
      showRebounding.value = true;
      percent.value = 0;
      updatePercent();
      try {
        // 发送换绑网络请求
        if (deviceType.value) {
          const params = {
            apple_id: currentAppleId.value,
          };

          icloudRebound(params).then(async (res) => {
            console.log('res:::', res);
            if (res.data.code === 200 && res.data.message === "OK") {
              await refreshTab();
              isSuccess.value = true;
              showRebounding.value = false;
              gaEvent("ChangeBind_Success", {
                event_category: "Dashboard_Function",
                event_label: "Dashboard",
              });
              ElMessage.success(t("dashboard.ReboundSuc"));
            } else {
              // console.log("fail")
            }
          })
        } else {
          const params = {
            device_id: currentDeviceId.value,
          };
          let res = await reboundDevice(params);
          if (res.data.code === 200 && res.data.message === "OK") {
            await refreshTab();
            isSuccess.value = true;
            showRebounding.value = false;
            gaEvent("ChangeBind_Success", {
              event_category: "Dashboard_Function",
              event_label: "Dashboard",
            });
            ElMessage.success(t("dashboard.ReboundSuc"));
          } else {
            // console.log("fail")
          }
        }
      } catch (err) {
        console.log("err");
        showRebounding.value = false;
        showReboundFailed.value = true;
        gaEvent("ChangeBind_Fail", {
          event_category: "Dashboard_Function",
          event_label: "Dashboard",
        });
      }
    };

    const toRebound = () => {
      gaEvent("Change_Bind", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      if (store.state.subscripts?.effective_device_num > 0) {
        showReboundDialog.value = true;
      } else {
        showNoLocation.value = true;
      }
    };

    // 进度条的进度，取值范围0~100
    let percent = ref(0);
    // 任务完成状态：true：完成 false：未完成
    let isSuccess = ref(false);
    // 更新进度
    const updatePercent = () => {
      setTimeout(() => {
        // step为随机数
        let step = Math.ceil(Math.random() * 20);
        if (isSuccess.value) {
          // 最后一步直接走到100
          step = 100 - percent.value;
        } else {
          // 如果进度走到99了，任务还没有成功，就每次都加上0，相当于卡在不动
          step = percent.value + step >= 99 ? 0 : step;
        }
        percent.value += step;
        // 没有到100，就一直调用该函数
        if (percent.value < 100) {
          updatePercent();
        } else {
        }
      }, 500);
    };

    // 首页新增数据
    const dashNewData = ref<any[]>([])
    // 获取首页新增
    const getCurrDashNew = async () => {
      try {
        const params = {
          device_id: id.value
        }
        const res = await getDashNew(params)
        console.log('resss', res, dashNewData.value);
        if (res.data.code == 200 && res.data.message == 'OK') {
          dashNewData.value = []
          Object.keys(res.data.data).forEach(key => {
            // console.log(key, res.data.data[key]);
            if (key == 'call') {
              dashNewData.value.push({
                'name': 'Calls',
                'src': require("@/components/homePage/img/calls.svg"),
                'path': 'calllogs',
                ...res.data.data[key]
              })
            } else if (key == 'message') {
              dashNewData.value.push({
                'name': 'Messages',
                'src': require("@/components/homePage/img/messages.svg"),
                'path': 'messages',
                ...res.data.data[key]
              })
            } else if (key == 'contact') {
              dashNewData.value.push({
                'name': 'Contacts',
                'src': require("@/components/homePage/img/contacts.svg"),
                'path': 'contacts',
                ...res.data.data[key]
              })
            } else if (key == 'photo') {
              dashNewData.value.push({
                'name': 'Photos',
                'src': require("@/components/homePage/img/photos.svg"),
                'path': "photos",
                ...res.data.data[key]
              })
            } else {
              dashNewData.value.push({
                'name': 'Applications',
                'src': require("@/components/homePage/img/application.svg"),
                'path': "installedApps",
                ...res.data.data[key]
              })
            }
          });
          console.log('dashNewData', dashNewData.value);
        }
      } catch (err) {
      }
    }

    const jumpToMenu = (jumpPath: any) => {
      store.commit("setDefaultMenu", jumpPath);
      router.push({ path: jumpPath });
    }

    // 获取屏幕统计数据
    // 增长比例
    const useProportion = ref(0)
    // 日均使用
    const daliyTime = ref('')
    // 没周使用时长
    const weekUse = ref([])
    // 使用app频率排行
    const appUseList = ref<any[]>([]);
    const getScreenCount = async () => {
      try {
        const params = {
          device_id: id.value
        }
        const res = await getDashCount(params)
        if (res.data.code == 200 && res.data.message == 'OK') {
          console.log('screenCount', res);
          weekUse.value = res.data.data.weekly_usage
          daliyTime.value = convertSecondsToHoursMinutes(res.data.data.daily_usage)
          useProportion.value = res.data.data.usage_proportion ? Math.round(res.data.data.usage_proportion * 100) : 0
          if (res.data.data.apps) {
            if (res.data.data.apps.length >= 5) {
              appUseList.value = res.data.data.apps.splice(0, 5)
            } else {
              appUseList.value = res.data.data.apps
            }
          }

          if (appUseList.value.length) {
            let aaa = generateBarWidths(appUseList.value)
            appUseList.value.forEach((item: any, index: any) => {
              item.perc = aaa[index]
            })
          }
          console.log('appUseList.value', appUseList.value);
        }

        setTimeout(() => {
          renderBarChart()
        }, 1000)
      } catch (err) {
        setTimeout(() => {
          renderBarChart()
        }, 1000)
      }
    }

    const renderBarChart = () => {
      let chartContainer: any
      chartContainer = document.querySelector('#chartContainer')
      const chart = echarts.init(chartContainer);
      const data = weekUse.value; // Replace with your data values
      const categories = [t("famikeep200['Sun']"), t("famikeep200['Mon']"), t("famikeep200['Tue']"), t("famikeep200['Wed']"), t("famikeep200['Thu']"), t("famikeep200['Fri']"), t("famikeep200['Sat']")]; // Replace with your categories

      const average = data.reduce((sum, value) => sum + value, 0) / data.length;
      const echartsGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: 'rgba(47, 104, 235, 1)' }, // Start color
        { offset: 1, color: 'rgba(255, 255, 255, 0.00)' }, // End color
      ]);
      let maxLength = 0
      // 选数组中最大的去计算长度
      const option = {
        grid: {
          left: 47,
          right: 10,
          top: 10, // Adjust top margin as needed
          bottom: 30, // Adjust bottom margin as needed
        },
        xAxis: {
          type: 'category',
          data: categories,
          axisLine: {
            show: false, // Hide X axis line
          },
          axisTick: {
            show: false, // Hide X axis tick marks
          },
          splitLine: {
            show: true, // Show split lines on X axis
            lineStyle: {
              type: 'dashed', // Set line style to dashed
            },
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true, // Show split lines on Y axis
            lineStyle: {
              type: 'dashed', // Set line style to dashed
            },
          },
          // interval: 1000 * 60,
          axisLabel: {
            formatter: (value: number) => {
              return convertSecondsToHours(value); // Assuming you have the convertSecondsToHoursMinutes function
            },
            width: 100, // 设置坐标轴标签的宽度
          },
        },
        tooltip: {
          trigger: 'axis', // Show tooltip on hover over a data point
          show: true,
          formatter: (params: any) => {
            const value = params[0].value;
            return convertSecondsToHoursMinutesSecond(value); // Assuming you have the convertSecondsToHoursMinutes function
          },
        },
        series: [
          {
            name: 'Data',
            type: 'bar',
            data: data,
            itemStyle: {
              color: echartsGradient,
              barBorderRadius: [4, 4, 0, 0],
            },
          },
          {
            name: 'Average',
            type: 'line',
            data: new Array(data.length).fill(average),
            lineStyle: {
              color: '#67C23A', // Customize the color of the average line
              type: 'dashed',
            },
          },
        ],
      };

      chart.setOption(option);
    }

    // 获取地理位置
    const locationsList = ref<any[]>([])
    const lcoationMap = ref([0, 0])//经纬度
    const getGeofencFun = async () => {
      try {
        locationsList.value = []
        let params: any = {}
        if (isVibe.value) {
          params = {
            device_id: id.value,
            start_date: 1659283200,
            end_date: 2195740800,
            apple_id: currentAppleId.value,
            os: deviceType.value == 1 ? 'ios' : 'android'
          }
          if (deviceType.value)
            delete params.apple_id;
        } else {
          if (deviceType.value) {
            params = {
              device_id: id.value,
              apple_id: currentAppleId.value,
              os: deviceType.value == 1 ? 'ios' : 'android'
            }
          } else {
            params = {
              device_id: id.value,
            }
          }
        }

        const res = await getLocationApi(params);
        console.log('res.data:', res.data.code);

        if (res.data.code == 200) {
          loading.value = false
          if (res.data.data.locations) {
            locationsList.value = res.data.data.locations || []
            if (deviceType.value) {
              if (locationsList.value.length > 7) {
                locationsList.value = locationsList.value.slice(0, 7)
              }
              console.log("开始处理地址信息");

              locationsList.value.forEach((item: any, index) => {
                setTimeout(async () => {
                  let res = await getAddressInfo([item.longitude, item.latitude])
                  item.detail = res[0]
                  console.log("getAddressInfores:", res);
                }, 2000)
              })
            } else {
              if (locationsList.value.length > 5) {
                locationsList.value = locationsList.value.slice(0, 5)
              }
            }
            if (res.data.data.locations.length) {
              lcoationMap.value = [locationsList.value[0].longitude, locationsList.value[0].latitude]
            }
            console.log('locationsList', locationsList.value, lcoationMap.value);
          }
        }
      } catch (err: any) {
        console.log('errr', Object.keys(err));
        console.log('errr', err.response);
        if (err.response) {
          if (err.response.data.code == 401003) {
            console.log('401003');
            if (messageToastType.value !== 3) {
              store.commit('setMessageToastType', 4);
            }
            if (!window.location.href.includes('home')) {
              router.push({ path: '/iosLogin' });
              store.commit('setDefaultMenu', null);
            }
          }
        }

      } finally {
        console.log('finally');
        loading.value = false
      }
    }

    // 用于显示坐标轴的y轴
    const convertSecondsToHours = (seconds: number): string => {
      let yScale = ''
      // 小于一小时
      if (seconds < 1 * 60 * 60) {
        const minutes = parseFloat(((seconds % 3600) / 60).toFixed(1));
        console.log('minutes:', minutes);

        yScale = minutes > 0 ? `${minutes}m` : '';
        if (seconds < 1 * 60) {
          return `${seconds}s`
        }
      } else {
        const hours = parseFloat((seconds / 3600).toFixed(1));
        console.log('hours:', hours);
        yScale = hours > 0 ? `${hours}h` : '';
      }
      console.log('yScale:', yScale);
      return yScale
    }

    // 计算宽度百分比
    const generateBarWidths = (data: any) => {
      const maxUsage = Math.max(...data.map((item: any) => item.usage));
      const barWidths = data.map((item: any) => (item.usage / maxUsage) * 100);

      return barWidths;
    }


    // ----------------------
    const toLoginIcloud = () => {
      store.commit("setDefaultMenu", null);
      store.commit('setIsShowBack', true)
      store.commit('setIsExpiredLogin', true)
      router.push('iosLogin');
    }

    const showIosList = ref(false)
    const iosList = ref([])

    watch(currentAppleId, (newV: any) => {
      deviceInfo.value.forEach((item: any, index: any) => {
        if (item.apple_id == newV) {
          iosList.value = deviceInfo.value[index].devices
        }
      })
    }, {
      immediate: true
    })

    const changeDevice = () => {
      if (deviceType.value && store.state.currentDeviceInfo.id) {
        if (showIosList.value) {
          showIosList.value = false
        } else {
          showIosList.value = true
        }
      }
    }

    const changeDeviceModel = async (index: any) => {
      store.commit("setCurrentDeviceId", (iosList.value[index] as any).id);
      // let aaa = JSON.parse(JSON.stringify(iosList.value[index]))
      // aaa.storage_usage = '1111'
      console.log('iosList.value', iosList.value, store.state.currentDeviceInfo);

      await store.commit("setCurrentDeviceInfo", { ...(iosList.value as any)[index], is_expired: store.state.currentDeviceInfo.is_expired, expire_at: store.state.currentDeviceInfo.expire_at, storage_usage: store.state.currentDeviceInfo.storage_usage });

      // 修改菜单列表
      store.dispatch("updateMenuList", { deviceInfo: deviceInfo.value, getCurrent: true })

      await getGeofencFun()

      showIosList.value = false
    }

    interface PhotoItem {
      // 根据实际情况定义 PhotoItem 的属性
      date: string;
      // ... 其他属性
    }
    const photoListArray: Ref<PhotoItem[][]> = ref([]);//图片数组

    const getPhotoList = async () => {
      photoListArray.value = []
      let photoApi: any
      let params: any

      if (!showRebound.value) {
        photoApi = photoInfo
        params = {
          type: 1,
          start_date: 1,
          end_date: 2195740800,
          apple_id: currentAppleId.value
        }

      } else {
        photoApi = iosPhotosApi
        params = {
          page: 1,
        }
      }
      // const chatListSourceRef = computed(() => store.state.photoInfoSource);

      // // 取消掉上一次请求
      // chatListSourceRef.value &&
      //   chatListSourceRef.value.cancel("请勿频繁请求会话列表接口");


      try {
        photoApi(params, currentAppleId.value).then((res: any) => {
          console.log('resss:', res);
          if (res.data.data.elements) {
            photoListArray.value = res.data.data.elements || []
            if (photoListArray.value.length > 5) {
              photoListArray.value = photoListArray.value.slice(0, 5)
            }
          }

        })
          .catch((err: any) => {
            console.log('errrrrrr111', err);
          })

      } catch (err) {
        console.log('errrrrrr', err);

      }
    }


    return {
      isVibe,
      percent,
      autoRenew,
      lang,
      store,
      loading,
      subscripts,
      isDemo,
      account,
      showUnbind,
      showUnbindDialog,
      showReboundDialog,
      showReboundFailed,
      showRebounding,
      showNoLocation,
      showRebound,
      targetDevice,
      currentDevice,
      currentDeviceModel,
      active_calls,
      active_session,
      ins_calls,
      ins_session,
      beExpired,
      isExpired,
      deveiceHeight,
      goPay,
      toUnbind,
      toRebound,
      unbindDevice,
      confirmUnbind,
      confirmRebind,
      closeDialog,
      closeReboundDialog,
      closeReboundFailed,
      closeNoLocation,
      // closeExpired,
      jumpToCall,
      jumpToContact,
      jumpToInsContact,
      jumpToInsCall,
      dateFormat,
      dateFormatAm,
      getCurrentDashBoard,
      handleUpdateClick,
      handleClickOutside,
      // updateText,
      messageToastType,
      deviceId,
      defaultMenu,
      deviceInfo,

      // 首页新增
      syncLoading,
      dashNewData,
      jumpToMenu,

      // 屏幕数据统计
      useProportion,
      daliyTime,
      weekUse,
      getScreenCount,

      // 使用app频率
      appUseList,
      jumpToLocation,
      // 地图
      locationsList,
      lcoationMap,
      getGeofencFun,


      // --------------------
      deviceType,
      toLoginIcloud,
      iphone,
      Launcher,
      isActive,
      storage_usage,
      iosDevice,
      changeDevice,
      changeDeviceModel,
      showIosList,
      currentAppleId,
      currentDeviceId,
      iosList,
      jumpToPhoto,
      photoListArray,
      getPhotoList,
      handleClickIosList,
    };
  },
});
